import React from "react";
import "./Recuadro.css";

export const Recuadro = ({ source, imagenTexto, direccion, texto }) => {
  return (
    <>
      <div className="recuadro-cursos">
        <img src={source} alt={imagenTexto} />
        <a href={direccion}>
          <h3>{texto}</h3>
        </a>
      </div>
    </>
  );
};
