import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import logo from "../../images/logo-1.webp";
import "./Header.css";

const Header = (props) => {
  const URL = window.location.pathname;

  const menu = (
    <ul>
      <li>
        <NavLink activeClassName={URL === "/" ? "active" : ""} to="/">
          Inicio
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="active" to="/cursos">
          Cursos
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="active" to="/preguntas">
          Preguntas
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="active" to="/blog">
          Blog
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="active" to="/sobre-nosotros">
          Nosotros
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="active" to="/contacto">
          Contacto
        </NavLink>
      </li>
      <li className={"nav-btn"}>
        <NavLink activeClassName="active" to="/inscribirse">
          Inscribirse
        </NavLink>
      </li>
    </ul>
  );
  const [open, setOpen] = useState(false);
  const toggleMenu = () => {
    setOpen(!open);
  };
  return (
    <header>
      <div className="container container-header">
        <nav>
          <NavLink to="/">
            <img src={logo} alt="Cursos de ofimatica" />
          </NavLink>
          {menu}
          <span onClick={toggleMenu}>
            <FontAwesomeIcon icon={faBars} />
          </span>
        </nav>
        <section className={`mobile-menu ${open ? "open" : ""}`}>
          {menu}
        </section>
      </div>
    </header>
  );
};

export default Header;
