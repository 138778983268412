import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import Acceder from "./components/pages/Acceder";
import Blog from "./components/pages/Blog";
import Carrito from "./components/pages/Carrito";
import Categorias from "./components/pages/Categoria";
import Contacto from "./components/pages/Contacto";
import Cursos from "./components/pages/Cursos";
import Home from "./components/pages/Home";
import LandingExcel from "./components/pages/LandingExcel";
import LandingNinos from "./components/pages/LandingNinos";
import LandingWord from "./components/pages/LandingWord";
import Nosotros from "./components/pages/Nosotros";
import Politicas from "./components/pages/Politicas";
import Preguntas from "./components/pages/Preguntas";

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/cursos/:slug/:seo">
          <Cursos />
        </Route>
        <Route exact path="/cursos/:slug">
          <Cursos />
        </Route>
        <Route exact path="/cursos">
          <Cursos />
        </Route>
        <Route exact path="/carrito">
          <Carrito />
        </Route>
        <Route exact path="/inscribirse">
          <Acceder />
        </Route>
        <Route exact path="/contacto">
          <Contacto />
        </Route>
        <Route exact path="/sobre-nosotros">
          <Nosotros />
        </Route>
        <Route exact path="/blog/:slug">
          <Blog />
        </Route>
        <Route exact path="/blog">
          <Blog />
        </Route>
        <Route exact path="/categoria/:slug">
          <Categorias />
        </Route>
        <Route exact path="/categoria">
          <Blog />
        </Route>

        <Route exact path="/preguntas">
          <Preguntas />
        </Route>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/politicas-y-privacidad-de-uso">
          <Politicas />
        </Route>
        <Route exact path="/landing-excel">
          <LandingExcel />
        </Route>
        <Route exact path="/landing-word">
          <LandingWord />
        </Route>
        <Route exact path="/landing-ninos">
          <LandingNinos />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
