import React, { useState, useEffect } from "react";
import "./ModalCookies.css";

const ModalCookies = (props) => {
  const [estado, setCookies] = useState(false);
  const [rechazar, setRechazar] = useState(false);
  const [fondo, setFondo] = useState(false);

  function setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    const expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
  }

  function checkCookie(cname) {
    //string.trimStart()
    const laCokkie = document.cookie;
    const testr = laCokkie.split(";");
    //console.log(testr);
    //let valorCookie = testr[0].split("=");
    const newCookies = testr.map((x) => x.trimStart());
    //console.log(newCookies);
    let policyCookie = false;
    for (let index = 0; index < newCookies.length; index++) {
      if (newCookies[index].split("=")[0] == "politicas") {
        policyCookie = true;
        console.log("existe la politica en cookie!");
      }
    }
    //var cookieValor = document.cookie.replace(/(?:(?:^|.*;\s*)test2\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    return policyCookie;
  }

  useEffect(() => {
    if (estado) {
      setCookie("politicas", "true", 30);
      setCookie("test", "ni ideaa", 30);
      setCookie("otro", "legal", 30);
    }
  }, [estado]);
  if (!checkCookie("politicas")) {
    return (
      <>
        <div className={`background-cookie ocultar-fondo-${fondo}`} />
        <div className={`modal-cookie ${estado} ocultar-${rechazar}`}>
          <div className="modal-cookie__content">
            <p>
              Utilizamos cookies propias y de terceros para ofrecer una mejor
              experiencia y contenido relevante al usuario a través de su
              navegación en ella.
              <a href="/politicas-y-privacidad-de-uso"> Políticas y privacidad de uso.</a>
            </p>
          </div>
          <div className="modal-cookie__buttons">
            <button
              type="button"
              className="modal-cookie__buttons--accept"
              onClick={() => {
                setCookies(true);
                setFondo(true);
              }}
            >
              Aceptar
            </button>
            <button
              type="button"
              className="modal-cookie__buttons--reject"
              onClick={() => {
                setCookies(false);
                setFondo(true);
                setRechazar(true);
              }}
            >
              Rechazar
            </button>
          </div>
        </div>
      </>
    );
  } else {
    return <></>;
  }
};

export default ModalCookies;
