import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import ApiCache from "../utlis/ApiCache";
import mejorarImg from "../utlis/mejorarImg";
import mejorarTitulo from "../utlis/mejorarTitulo";
import mejorarTituloBlog from "../utlis/mejorarTituloBlog";
import "./Footer.css";

const Footer = () => {
  //const endpoint = process.env.REACT_APP_API_WP + `posts?per_page=2&_embed`;
  const endpoint = process.env.REACT_APP_API_WP + `posts?page=1&_embed`;
  const [footerPost, setFooterPost] = useState([]);
  const anhoActual = new Date().getFullYear();
  let isMounted = true;

  useEffect(() => {
    //ApiCache("footer-blogs", endpoint).then((r) => {
    ApiCache("todos-blogs-page-1-filters-", endpoint).then((r) => {
      const posts = r.map((post) => {
        return {
          id: post.id,
          img:
            post["_embedded"]["wp:featuredmedia"][0].media_details.sizes
              .thumbnail.source_url ?? "",
          title: post.title.rendered,
          slug: post.slug,
        };
      });
      if (isMounted) {
        setFooterPost(
          posts.filter((post, index) => {
            return index === 0 || index === 1;
          })
        );
      }
    });
    return () => {
      isMounted = false;
      return false;
    };
  }, [endpoint]);

  const urlWebP = "https://www.mktyventas.com/image2.php?url=";
  const blog_footer = footerPost.map((blog) => {
    //console.log(blog)
    return (
      <div key={blog.id} className="entry">
        <div className="img">
          <img src={mejorarImg(blog.img)} alt="" />
        </div>
        <div className="title">
          <NavLink
            activeClassName="active"
            to={`/blog/${mejorarTitulo(blog.slug)}`}
          >
            <h4 className="footer_titulo--mayuscula">
              {mejorarTituloBlog(blog.title)}
            </h4>
          </NavLink>
        </div>
      </div>
    );
  });

  return (
    <>
      <footer>
        <div className="container">
          <div className="paginas">
            <h3>Páginas</h3>
            <div className="seccion-links">
              <div className="primera-seccion-links">
                <ul>
                  <li>
                    <NavLink activeClassName="active" to="/">
                      Inicio
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/cursos-marketing-educativo"
                    >
                      Cursos
                    </NavLink>
                  </li>

                  <li>
                    <NavLink activeClassName="active" to="/blog">
                      Blog
                    </NavLink>
                  </li>

                  <li>
                    <NavLink activeClassName="active" to="/preguntas">
                      Preguntas
                    </NavLink>
                  </li>
                </ul>
              </div>

              <div className="segunda-seccion-links">
                <ul>
                  <li>
                    <NavLink activeClassName="active" to="/sobre-nosotros">
                      Nosotros
                    </NavLink>
                  </li>

                  <li>
                    <NavLink activeClassName="active" to="/contacto">
                      Contacto
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/terminos-y-condiciones"
                      target="_blank"
                    >
                      Términos y Condiciones
                    </NavLink>
                  </li>

                  <li>
                    <NavLink
                      activeClassName="active"
                      to="/cookies-y-privacidad"
                      target="_blank"
                    >
                      Cookies y Privacidad
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <hr className="linea" />

          <div className="blog-footer">
            <h3>Últimas entradas</h3>
            <div className="entries">{blog_footer}</div>
          </div>
        </div>

        <section className="derechos-reservados">
          <p className="derechos-reservados__texto">
            <strong>Cursos de Ofimática</strong> es un servicio ofrecido por IDT
            S.A. y Grupo IDT, LLC | Todos los Derechos Reservados © {anhoActual}
          </p>
        </section>
      </footer>
    </>
  );
};

export default Footer;
