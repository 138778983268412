import React from 'react';
import Header from "../common/Header";
import './css/Nosotros.css'

import Footer from "../common/Footer";

const Carrito = (props) => {

    return (
        <>
            <Header/>
                <h3 style={ {textAlign:'center', margin:100}}>En construcción, textilia</h3>
            <Footer />
        </>

    );
};

export default Carrito;
