const mejorarTitulo = (titulo) => {
  const mejorar = titulo.toLowerCase();
  const mejorar1 = mejorar
    .replace(/ /g, "-")
    .replace("?", "")
    .replace("¿", "")
    .replace("!", "")
    .replace("¡", "");
  const mejorar2 = mejorar1
    .replace(/ñ/g, "n")
    .replace(/á/g, "a")
    .replace(/é/g, "e")
    .replace(/í/g, "i")
    .replace(/ó/g, "o")
    .replace(/ú/g, "u")
    .replace(/ü/g, "u");
  return mejorar2;
};

export default mejorarTitulo;
