import { faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./BotonDeSubir.css";

const BotonDeSubir = () => {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="botonDeSubir" onClick={handleClick}>
      <FontAwesomeIcon icon={faArrowUp} />
    </div>
  );
};

export default BotonDeSubir;
