/* import LogoMarketing from "../../images/Logo-Marketing.png"; */

const mejorarImg = (imgx) => {
  if (imgx === null || imgx === "" || imgx === undefined) {
    /* return { LogoMarketing }; */
    return "";
  }

  //console.log(imgx);
  let imgx2 = imgx.split("-");
  //console.log(imgx2);
  const ultimo = imgx2[imgx2.length - 1];
  const vectorExtension = ultimo.split(".");
  imgx2.pop();
  //console.log(imgx2.join("-") + "." + vectorExtension[1]);
  return `${imgx2.join("-")}.${vectorExtension[1]}`;
};

export default mejorarImg;
